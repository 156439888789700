//
// Color
//

// basic
$color-primary-darkest                : rgb(043, 063, 087); // #2b3f57: dark-grey-blue
$color-primary-darker                 : rgb(039, 077, 134); // #274d86: dusk-blue
$color-primary-dark                   : rgb(028, 084, 168); // #1c54a8: sapphire
$color-primary                        : rgb(042, 105, 199); // #2a69c7: medium-blue
$color-primary-light                  : rgb(137, 187, 242); // #89bbf2: sky
$color-primary-lighter                : rgb(212, 225, 244); // #d4e1f4: light-periwinkle
$color-primary-lightest               : rgb(233, 240, 249); // #e9f0f9: ice-blue

// $color-secondary-dark                 : rgb(047, 059, 082); // #2f3b52
$color-secondary                      : rgb(54, 129, 111); // #36816f
// $color-secondary-light                : rgb(000, 000, 000); // #000000
$color-secondary-lightest             : rgb(226, 243, 233); // #e2f3e9

// $color-thirty-dark                    : rgb(000, 000, 000); // #000000
// $color-thirty                         : rgb(000, 000, 000); // #000000
// $color-thirty-light                   : rgb(000, 000, 000); // #000000

$color-danger                         : rgb(213, 049, 049); // #d53131: reddish
$color-warn                           : rgb(239, 132, 060); // #ef843c: dusty-orange
$color-warn-lightest                 : rgb(255, 236, 224); // #ffece0: light-orange
$color-urgent                          : rgb(196, 64,57); // #c44039 darken-red
// pink
$pink-light                           : rgb(253, 242, 235); // #fdf2eb: very-light-pink

// gray
$gray-black                           : rgb(000, 000, 000); // #000000
$gray-darkest                         : rgb(097, 103, 111); // #61676f: slate-grey
$gray-darker                          : rgb(129, 136, 145); // #818891: steel
$gray-dark                            : rgb(170, 178, 188); // #aab2bc: light-grey-blue
$gray                                 : rgb(186, 196, 211); // #bac4d3: cloudy-blue
$gray-light                           : rgb(223, 225, 230); // #dfe1e6: light-gray
$gray-lighter                         : rgb(243, 245, 246); // #f3f5f6: ice-gray
$gray-lightest                        : rgb(241, 243, 249); // #f1f3f9: light-pale-grey
$gray-white                           : rgb(255, 255, 255); // #ffffff

// blue
$checkbox-blue                        : rgb(57, 135, 253); //#3987FD

//
// Transition
//

$transition-mode                      : cubic-bezier( .25, .8, .25, 1);


//
// Texting
//

$font-family                          : "system", "-apple-system", "PingFang-TC", "Segoe UI", "Microsoft JhengHei", "Roboto", "Oxygen", "Fira Sans", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";

$font-size-xl                         : 20px;
$font-size-lg                         : 18px;
$font-size-md                         : 16px;
$font-size-sm                         : 14px;
$font-size-xs                         : 12px;


// Responsive Width
$tablet-width                         : 768px;
$mobile-width                         : 375px;
$tablet-L                             : 1024px;
$mobile-L                             : 425px;

@mixin full-fill($position: absolute) {
  position: $position;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin image-fluid {
  max-width: 100%;
  height: auto;
}

@mixin image-centre($height, $size: "contain", $child: "img") {
  height: $height;
  line-height: $height;
  text-align: center;
  overflow: hidden;

  > #{$child} {
    @include image-centre-child($size);
  }
}

@mixin image-centre-child($size: "contain") {
  margin: -100%;

  @if $size == "contain" {
    max-height: 100%;
    max-width: 100%;
  } @else if $size == "cover-by-width" {
    min-width: 100%;
  } @else if $size == "cover-by-height" {
    min-height: 100%;
  }
}

@mixin position-centre($position: absolute) {
  position: $position;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin position-center-over-top($transform...) {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%) #{$transform};
}

@mixin position-center-over-right($transform...) {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(100%, -50%) #{$transform};
}

@mixin position-center-over-bottom($transform...) {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%) #{$transform};
}

@mixin position-center-over-left($transform...) {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-100%, -50%) #{$transform};
}

@mixin position-top-over-left($transform...) {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-100%, 0%) #{$transform};
}

@mixin position-top-over-right($transform...) {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(100%, 0%) #{$transform};
}

@mixin position-right-over-top($transform...) {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(0%, -100%) #{$transform};
}

@mixin position-right-over-bottom($transform...) {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(0%, 100%) #{$transform};
}

@mixin position-left-over-top($transform...) {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0%, -100%) #{$transform};
}

@mixin position-left-over-bottom($transform...) {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(0%, 100%) #{$transform};
}

@mixin position-bottom-over-left($transform...) {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(-100%, 0%) #{$transform};
}

@mixin position-bottom-over-right($transform...) {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(100%, 0%) #{$transform};
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

/**
 * Pure CSS text truncate
 * See [Line Clampin' (Truncating Multiple Line Text) - Weird WebKit Flexbox Way](https://css-tricks.com/line-clampin/)
 */

@mixin text-truncate-singleline($width: 100%) {
  // Requires inline-block or block for proper styling
  // not work at inline-flex and flex

  width: $width;
  white-space: nowrap;
  @include text-truncate-style;
}

@mixin text-truncate-multiline($line-to-show, $line-height: 1.5, $font-size: 1em) {
  display: block; // fallback
  display: -webkit-box;
  line-height: $line-height;
  max-height: $font-size * $line-height * $line-to-show; // fallback
  -webkit-line-clamp: $line-to-show;
  @include text-truncate-style;

  // *** IMPORTANT SEE ***:
  // 1. https://github.com/postcss/autoprefixer/issues/776#issuecomment-392346351
  // 2. https://github.com/NoName4Me/yo-FE/issues/3
  // eslint-disable-next-line
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
}

@mixin text-truncate-style {
  overflow: hidden;
  text-overflow: ellipsis;
}

/**
 * 1. Remove white space between inline-block
 * See [Fighting the Space Between Inline Block Elements](https://css-tricks.com/fighting-the-space-between-inline-block-elements/)
 */

@mixin vertical-align($height: 100%, $font-size: 1rem, $align: middle) {
  height: $height;
  font-size: 0; /* 1 */

  &:before {
    content: "";
    width: 0;
    height: 100%;
    @include vertical-align-style($align);
  }

  > * {
    font-size: $font-size; /* 1 */
    @include vertical-align-style($align);
  }
}

@mixin vertical-align-style($align) {
  display: inline-block;
  vertical-align: $align;
}

$margin-horizontal: 10px;

$border-width: 1px;

// ---
// radiuses
$radiuses: (
  xs: 3px,
  sm: 3px,
  md: 5px,
  lg: 5px
);

// sizes
$sizes: (
  xs: (
    height: 22px
  ),
  sm: (
    height: 34px
  ),
  md: (
    height: 44px
  ),
  lg: (
    height: 44px
  )
);

// shapes
$shapes: (
  rect: (
    xs: 0px,
    sm: 0px,
    md: 0px,
    lg: 0px
  ),
  radius: (
    xs: 3px,
    sm: 3px,
    md: 5px,
    lg: 5px
  ),
  rounded: (
    xs: 100%,
    sm: 100%,
    md: 100%,
    lg: 100%
  ),
  circle: (
    xs: 50%,
    sm: 50%,
    md: 50%,
    lg: 50%
  )
);

// ---
// directions
$directions: (vertical, horizontal);

// ---
// types
$types: (
  default: (
    border-color: $gray-light,
    border-color-activate: $color-primary
  ),
  primary: (
    border-color: rgba($gray-white, 0.25),
    border-color-activate: rgba($gray-white, 0.5)
  ),
  danger: (
    border-color: rgba($gray-white, 0.25),
    border-color-activate: rgba($gray-white, 0.5)
  )
);

.button-group {
  &[data-is-block="true"] {
    display: flex;
    width: 100%;
  }

  &[data-is-block="false"] {
    display: inline-flex;
    vertical-align: middle;
  }

  &[data-direction="vertical"] {
    flex-direction: column;

    & > :not(:disabled)[role="button"][data-is-filled="true"] {
      @each $type, $styles in $types {
        &[data-type="#{$type}"] {
          &:first-of-type {
            border-bottom-color: map-get($styles, border-color);
          }

          &:not(:first-of-type):not(:last-of-type) {
            border-bottom-color: map-get($styles, border-color);
            border-top-color: map-get($styles, border-color);
          }

          &:last-of-type {
            border-top-color: map-get($styles, border-color);
          }

          &:hover,
          &:focus {
            &:first-of-type {
              border-bottom-color: map-get($styles, border-color-activate);
            }

            &:not(:first-of-type):not(:last-of-type) {
              border-bottom-color: map-get($styles, border-color-activate);
              border-top-color: map-get($styles, border-color-activate);
            }

            &:last-of-type {
              border-top-color: map-get($styles, border-color-activate);
            }
          }
        }
      }

      @each $shape, $shape-sizes in $shapes {
        &[data-shape="#{$shape}"] {
          @each $shape-size, $border-radius in $shape-sizes {
            &[data-size="#{$shape-size}"] {
              border-radius: 0;

              @if ($shape == "rounded") {
                &:first-of-type {
                  border-top-left-radius: #{map-get(map-get($sizes, $shape-size), height)} $border-radius;
                  border-top-right-radius: #{map-get(map-get($sizes, $shape-size), height)} $border-radius;
                }
                &:last-of-type {
                  border-bottom-left-radius: #{map-get(map-get($sizes, $shape-size), height)} $border-radius;
                  border-bottom-right-radius: #{map-get(map-get($sizes, $shape-size), height)} $border-radius;
                }
              } @else {
                &:first-of-type {
                  border-top-left-radius: $border-radius;
                  border-top-right-radius: $border-radius;
                }
                &:last-of-type {
                  border-bottom-left-radius: $border-radius;
                  border-bottom-right-radius: $border-radius;
                }
              }

              &:not(:first-of-type) {
                margin-top: -$border-width;
              }
            }
          }
        }
      }
    }
  }

  &[data-direction="horizontal"] {
    flex-direction: row;

    & > :not(:disabled)[role="button"][data-is-filled="true"] {
      @each $type, $styles in $types {
        &[data-type="#{$type}"] {
          &:first-of-type {
            border-right-color: map-get($styles, border-color);
          }

          &:not(:first-of-type):not(:last-of-type) {
            border-right-color: map-get($styles, border-color);
            border-left-color: map-get($styles, border-color);
          }

          &:last-of-type {
            border-left-color: map-get($styles, border-color);
          }

          &:hover,
          &:focus {
            &:first-of-type {
              border-right-color: map-get($styles, border-color-activate);
            }

            &:not(:first-of-type):not(:last-of-type) {
              border-right-color: map-get($styles, border-color-activate);
              border-left-color: map-get($styles, border-color-activate);
            }

            &:last-of-type {
              border-left-color: map-get($styles, border-color-activate);
            }
          }
        }
      }

      @each $shape, $shape-sizes in $shapes {
        &[data-shape="#{$shape}"] {
          @each $shape-size, $border-radius in $shape-sizes {
            &[data-size="#{$shape-size}"] {
              border-radius: 0;

              @if ($shape == "rounded") {
                &:first-of-type {
                  border-top-left-radius: #{map-get(map-get($sizes, $shape-size), height)} $border-radius;
                  border-bottom-left-radius: #{map-get(map-get($sizes, $shape-size), height)} $border-radius;
                }
                &:last-of-type {
                  border-top-right-radius: #{map-get(map-get($sizes, $shape-size), height)} $border-radius;
                  border-bottom-right-radius: #{map-get(map-get($sizes, $shape-size), height)} $border-radius;
                }
              } @else {
                &:first-of-type {
                  border-top-left-radius: $border-radius;
                  border-bottom-left-radius: $border-radius;
                }
                &:last-of-type {
                  border-top-right-radius: $border-radius;
                  border-bottom-right-radius: $border-radius;
                }
              }

              &:not(:first-of-type) {
                margin-left: -$border-width;
              }
            }
          }
        }
      }
    }
  }

  & > :not(:disabled)[role="button"][data-is-filled="true"] {
    &:hover,
    &:focus {
      z-index: 1;
    }
  }
}
